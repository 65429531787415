﻿$unfocussed-fg: rgb(104, 129, 129);
$unfocussed-bg: transparent;
$hover-fg: #0093BD;
$hover-bg: #fff;
$focussed-fg: #0093BD;
$focussed-bg: #fff;
$selected-fg: #0093BD;
$selected-bg: #E8F5F8;
input[type=radio].toggleoption {
    position: absolute;
    border: solid 1px red;
    box-sizing: border-box;
    opacity: 0;

    &:focus + label {
        color: $focussed-fg;
        background-color: $focussed-bg;
        border-color: $focussed-fg;
        outline: dotted 2px $focussed-fg;
    }

    &:checked + label, &:checked:focus + label{
        color: $selected-fg;
        background-color: $selected-bg;
        border-color: $selected-fg;
    }

    & + label {
        cursor: pointer;
        position: relative;
        padding: 1em 2em;
        border-radius: 2px;
        border: solid 1px $unfocussed-fg;
        color: $unfocussed-fg;
        background-color: $unfocussed-bg;
        text-align: center;

        &:hover {
            color: $hover-fg;
            background-color: $hover-bg;
            border-color: $hover-fg;
        }
    }
}

