﻿$unfocussed-fg: rgb(104, 129, 129);
$unfocussed-bg: transparent;
$hover-fg: #0093BD;


fieldset.hidden-fieldset{
    border: none;
    margin: 0;
    padding: 0;
    display: block;

    & > legend{
        font-weight:normal;
        font-size: 0.875rem;
        padding-bottom: .5em;
        color: inherit;
    }
}
