﻿

.forceflex{
    display: flex;
    justify-content: space-between;
    gap: 1em;
    &.fill-even{
        & > *{
            margin-left: 0;
            margin-right: 0;
            flex-grow: 5;
        }
    }
}

